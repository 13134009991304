import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatBottomSheet, MatDialog, MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';

import * as moment from 'moment';
import * as _ from 'lodash';
import {CustomerDetailsComponent} from '../customer-details/customer-details.component';
import {CustomersService} from '../../services/customers.service';
import {ReportDownloadComponent} from '../report-download/report-download.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {CustomerCreateComponent} from '../customer-create/customer-create.component';
import {AddMainContactComponent} from '../add-main-contact/add-main-contact.component';
import {EditStakeholdersComponent} from '../edit-stakeholders/edit-stakeholders.component';
import {CollaborationDialogComponent} from '../collaboration-dialog/collaboration-dialog.component';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.css']
})
export class CustomerTableComponent {
  @Input() customers: Array<any>;
  @Input() node: Object;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  showValid = true;
  showExpired = true;
  filterQuantity: any = null;
  filterLength = 'weeks';
  filterName = '';
  hideDisabledProjects = true;
  hideDemoProjects = true;
  loadingProjects = false;
  filterExpiry = 'all';
  filterLicense = 'all';
  columnsToDisplay = ['name', 'created', 'licenceLevel', 'tier', 'expires', 'users', 'responders', 'contacts', 'lastLog', 'enabled', 'demo', 'actions'];

  licenses = {
    0: 'Trial',
    1: 'Event',
    2: 'RAYVN',
    3: 'Tiered',
    4: 'Network',
    5: 'DSB',
    9: 'Legacy'
  };

  tiers = {
    LICENSE_TIER_BASE: 'Base',
    LICENSE_TIER_STANDARD: 'Standard',
    LICENSE_TIER_ADVANCED: 'Advanced',
    LICENSE_TIER_ENTERPRISE: 'Enterprise'
  };
  private detailsDialog: MatDialogRef<CustomerDetailsComponent, any>;
  private addMainContactDialog: MatDialogRef<AddMainContactComponent, any>;
  private editStakeholdersDialog: MatDialogRef<EditStakeholdersComponent, any>;
  private detailCustomer: any;

  Object = Object;

  constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private customersSvc: CustomersService, private bottomSheet: MatBottomSheet) { }


  ngAfterViewInit() {
    this.loadProjects();

    const queryCustomerId = this.route.snapshot.queryParamMap.get('customerId');
    if (queryCustomerId) {
      const customer = this.getCustomer(queryCustomerId);
      this.selectRow(customer);
    }
  }

  loadProjects() {
    this.dataSource = new MatTableDataSource(this.customers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];

    if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0){
      _.forEach(this.dataSource.filteredData, project => {
        if (project.userLimit != null) {
          project._remainingUsers = project.userLimit - project._userCount;
          project._remainingUsersString = project._remainingUsers;
        } else {
          project._remainingUsers = Infinity;
          project._remainingUsersString = 'Unlimited';
        }
        if (project.contactLimit != null) {
          project._remainingContacts = project.contactLimit - project._contactCount;
          project._remainingContactsString = project._remainingContacts;
        } else {
          project._remainingContacts = Infinity;
          project._remainingContactsString = 'Unlimited';
        }
        if (project.responderLimit != null) {
          project._remainingResponders = project.responderLimit - project._responderCount;
          project._remainingRespondersString = project._remainingResponders;
        } else {
          project._remainingResponders = Infinity;
          project._remainingRespondersString = 'Unlimited';
        }
      });
    }
    this.filter();


  }

  toggleDisabledFilter() {
    this.customers = [];
    this.loadingProjects = true;
    this.hideDisabledProjects = !this.hideDisabledProjects;
    this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(res => {
      this.loadingProjects = false;
      this.customers = res;
      this.loadProjects();
    });
  }

  toggleDemoFilter() {
    this.customers = [];
    this.loadingProjects = true;
    this.hideDemoProjects = !this.hideDemoProjects;
    this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(res => {
      this.loadingProjects = false;
      this.customers = res;
      this.loadProjects();
    });
  }

  filterTimeUnit(timeUnit) {
    this.filterLength = timeUnit;
    this.filter();
  }

  toggleValid() {
    this.showValid = !this.showValid;
    this.filter();
  }

  toggleExpired() {
    this.showExpired = !this.showExpired;
    this.filter();
  }

  filter() {
    let filteredSource = this.customers;

    if (this.filterLicense !== 'all') {
      filteredSource = filteredSource.filter(customer => +customer.licenseLevel === +this.filterLicense);
    }

    if (this.filterExpiry === 'expired')     { filteredSource = this.invalidLicenses(); }
    else if (this.filterExpiry === 'valid')  { filteredSource = this.validLicenses(); }

    if (this.filterQuantity != null && this.filterQuantity > 0 && this.filterLength != null) {
      const filterMoment = moment().add(this.filterQuantity, this.filterLength);
      filteredSource = _.filter(filteredSource, customer => moment(customer.licenseExpiresAt).isBetween(moment(), filterMoment));
    }

    this.dataSource.data = filteredSource;

    this.dataSource.filter = this.filterName;
  }

  clearFilter() {
    this.filterLicense = 'all';
    this.filterLength = 'week';
    this.filterName = null;
    this.filterExpiry = 'all';
    this.filterQuantity = null;
    this.filter();
  }

  private averageUsers() {
    if (!this.customers || this.customers.length === 0) { return 0; }
    const averageUsers = this.dataSource.filteredData.reduce((total, customer) => total + customer._userCount as number, 0) / this.dataSource.filteredData.length;
    return Math.floor(averageUsers * 10) / 10;
  }

  private averageResponders() {
    if (!this.customers || this.customers.length === 0) { return 0; }
    const averageUsers = this.dataSource.filteredData.reduce((total, customer) => total + customer._responderCount as number, 0) / this.dataSource.filteredData.length;
    return Math.floor(averageUsers * 10) / 10;
  }

  private averageContacts() {
    if (!this.customers || this.customers.length === 0) { return 0; }
    const averageUsers = this.dataSource.filteredData.reduce((total, customer) => total + customer._contactCount as number, 0) / this.dataSource.filteredData.length;
    return Math.floor(averageUsers * 10) / 10;
  }


  openCustomerCreator() {
    this.selectRow({});
  }

  openFastCustomerCreator() {
    const createDialog = this.dialog.open(CustomerCreateComponent, {
      width: '900px',
      minHeight: '400px',
      data: { customer: {}, node: this.node },
      disableClose: true
    });
    createDialog.afterClosed().subscribe(result => {
      this.updateCustomerQuery(null);
      this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(cust => {
        this.customers = cust;
        this.ngAfterViewInit();
      });
    });
  }

  openCollaborationDialog() {
    const dialogRef = this.dialog.open(CollaborationDialogComponent, {
      width: '95vw',
      height: '95vh',
      data: { node: this.node, customers: this.dataSource.filteredData },
      disableClose: true
    });
  }

  getCustomer(id) {
    let returnObj = null;
    _.forEach(this.customers, c => {
        if (c.id === id) {
          returnObj = c;
        }
    });

    return returnObj;
  }

  updateCustomerQuery(customer) {
    const queryParams: Params = { customerId: customer ? customer.id : null};

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  selectRow(customer) {
    if (this.detailCustomer && customer.id === this.detailCustomer.id) { return; }
    this.updateCustomerQuery(customer);
    this.detailCustomer = customer;
    this.detailsDialog = this.dialog.open(CustomerDetailsComponent, {
      width: '1200px',
      minHeight: '600px',
      data: { customer: customer, node: this.node },
      disableClose: true
    });
    this.detailsDialog.afterClosed().subscribe(result => {
      this.updateCustomerQuery(null);
      this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(cust => {
        this.customers = cust;
        this.ngAfterViewInit();
      });
      this.detailsDialog = null;
      this.detailCustomer = null;
    });
  }

  editMainContact(customer) {
    console.log('addMainContact', customer);
    if (this.detailCustomer && customer.id === this.detailCustomer.id) { return; }
    this.addMainContactDialog = this.dialog.open(AddMainContactComponent, {
      width: '800px',
      minHeight: '300px',
      data: { customer: customer, node: this.node },
      disableClose: true
    });
    this.addMainContactDialog.afterClosed().subscribe(result => {
      this.updateCustomerQuery(null);
      this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(cust => {
        this.customers = cust;
        this.ngAfterViewInit();
      });
      this.addMainContactDialog = null;
    });
  }

  editStakeholders(customer) {
    console.log('editStakeholders', customer);
    if (this.detailCustomer && customer.id === this.detailCustomer.id) { return; }
    this.editStakeholdersDialog = this.dialog.open(EditStakeholdersComponent, {
      width: '800px',
      minHeight: '300px',
      data: { customer: customer, node: this.node, customers: this.customers },
      disableClose: true
    });
    this.editStakeholdersDialog.afterClosed().subscribe(result => {
      this.updateCustomerQuery(null);
      this.customersSvc.getNodeCustomers(this.node['url'], this.hideDisabledProjects, this.hideDemoProjects).subscribe(cust => {
        this.customers = cust;
        this.ngAfterViewInit();
      });
      this.editStakeholdersDialog = null;
    });
  }

  impersonateMainContact(customer) {
    this.customersSvc.impersonateProjectContact(this.node['url'], customer.id).subscribe(res => {
      console.log('impersonateMainContact', res);
      window.open(res['applicationUrl'], '_blank');
    });
  }

  /*
   * Helperfunctions
   */

  projectIsValid(project) {
    if (!project._links.mainContactCustomer) { return false; }
    return true;
  }

  validLicenses() {
    return this.customers ? this.customers.filter(c => c.validLisence) : [];
  }

  invalidLicenses() {
    return this.customers ? this.customers.filter(c => !c.validLisence) : [];
  }

  inThePast(timestamp, amount: any = 0, timeUnit: string = 'days') {
    const date = moment(timestamp);
    const beforeDate = moment().subtract(amount, timeUnit);
    return date.isValid() ? date.isBefore(beforeDate) : true;
  }

  humanReadable(timestamp) {
    if (timestamp === 0) { return 'Never'; }
    return moment(timestamp).fromNow();
  }

  dateFormat(timestamp, format = 'll') {
    if (timestamp === 0) { return 'No data'; }
    return moment(timestamp).format(format);
  }

  generateReport() {
    const bottomSheetRef = this.bottomSheet.open(ReportDownloadComponent, {
      ariaLabel: 'Download report'
    });
    console.log(this.node);
    bottomSheetRef.instance.node = this.node;
  }

}
