export const environment = {
  production: false,
  auth0: {
    clientID: 'TRTvZnFJE6JjFeNuUmSIPgW4d68RsUK6',
    domain: 'rayvn-admin.eu.auth0.com',
    responseType: 'token id_token',
    audience: 'https://rayvn-admin.eu.auth0.com/userinfo',
    redirectUri: 'https://admin.rayvn.global/callback',
    scope: 'openid'
  },
  url: {
    applications: 'https://admin.rayvn.global/application/list',
    geo: 'https://admin.rayvn.global/geo/context'
  }
};
