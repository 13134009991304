import {Component, Inject, OnInit} from '@angular/core';
import {CustomersService} from '../../services/customers.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogData} from '../save-customer-details-preview/save-customer-details-preview.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import * as _ from 'lodash';

@Component({
  selector: 'app-add-main-contact',
  templateUrl: './add-main-contact.component.html',
  styleUrls: ['./add-main-contact.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  ],
})
export class AddMainContactComponent implements OnInit {
  project: any;
  customer: any;
  node: any;
  licenseExpiresAt: any;
  errorSaving: boolean;

  saving = false;
  constructor(
    public dialogRef: MatDialogRef<AddMainContactComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private customerSvc: CustomersService,
    private _snackBar: MatSnackBar
  ) {
    this.customer = data.customer;
    this.node = data.node;
  }

  async ngOnInit() {
    this.customerSvc.getProject(this.node.url, this.customer.id).subscribe(project => {
      console.log(project['_embedded'].mainContactCustomer);
      this.project = project;
      if (!this.project._embedded.mainContactCustomer) {
        this.project._embedded.mainContactCustomer = {};
      }
    });

    this.customerSvc.getTeamsForProject(this.node.url, this.customer.id).subscribe(teams => {
      console.log(teams);
    });
  }
  save() {
    this.saving = true;

    delete this.project._embedded.mainContactCustomer._links;

    const mainContact = this.project._embedded.mainContactCustomer;

    const mainContact$ = mainContact.id
      ? this.customerSvc.updateProjectContact(this.node.url, mainContact.id, mainContact)
      : this.customerSvc.createProjectContact(this.node.url, this.project.id, mainContact);

    mainContact$.subscribe(user => {
      this.dialogRef.close(user);
      this.saving = false;
    }, error => {
      this.saving = false;
      this.errorSaving = true;
      this.openSnackBar(error);
    });

  }


  openSnackBar(error) {
    let errorText = '';
    if (error.error.error.errors.length === 1) {
      errorText = error.error.error.errors[0].message;
    } else if (error.error.error.errors.length > 1) {
      _.forEach(error.error.error.errors, e => {
        errorText += e.message + '\n';
      });
    }


    if (errorText.indexOf('content:') !== -1) {
      const textArray = errorText.split('content:');
      _.forEach(textArray, t => {
        if (t.substring(0, 5).indexOf('{') !== -1) {
          const newError = JSON.parse(t);
          try {
            errorText = JSON.parse(newError.error.errors[0].message).message;
          } catch (e) {
            errorText = newError.error.errors[0].message;
          }

        }
      });
    }
    this._snackBar.open('Error: ' + errorText , 'OK', {
      verticalPosition: 'top',
      politeness: 'assertive',
      panelClass: 'error-snackbar'
    });
  }

  close() {
    this.dialogRef.close();
  }
}
