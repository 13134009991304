import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleLicenseService {

  constructor(private http: HttpClient) { }

  getAll(nodeUrl){
    return this.http.get(nodeUrl + 'admin/v1/moduleLicenses').map(result => result['content']);
  }
  getAllTypes(nodeUrl){
    return this.http.get(nodeUrl + 'admin/v1/moduleLicenses/moduleTypes');
  }
}
