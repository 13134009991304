import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import * as _ from 'lodash';
import {CustomersService} from '../../services/customers.service';

export interface DialogData {
  project: any;
  formData: any;
  customer: any;
  customers: any;
  node: any;
  nodeUrl: any;
}


@Component({
  selector: 'app-save-customer-details-preview',
  templateUrl: './save-customer-details-preview.component.html',
  styleUrls: ['./save-customer-details-preview.component.css']
})
export class SaveCustomerDetailsPreviewComponent implements OnInit {
  changedValues: any = [];
  originalProject: any;
  controls: any;
  nodeUrl: string;
  constructor(public dialogRef: MatDialogRef<SaveCustomerDetailsPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private customerSvc: CustomersService
  ) { }

  ngOnInit() {

    console.log("data", this.data);


   this.originalProject = this.data.project;
    this.controls = this.data.formData.controls;

    console.log("exp:", this.controls['licenseExpiresAt']);

    this.nodeUrl = this.data.nodeUrl;

    for(let n in this.originalProject){
      console.log("n", n)

      for(let j in this.controls){
       if(j == n){
         if(this.originalProject[n] != this.controls[j].value){
           this.changedValues.push(n);
         }
       }
      }
    }

    console.log("ChangedVal", this.changedValues)

  }


  save(){
    let saveObject = {}

    _.forEach(this.changedValues, v=>{
      let saveableVal = this.controls[v].value;
      if(v == 'licenseExpiresAt'){
        saveableVal = this.controls[v].value.getTime();
      }
      saveObject[v] = saveableVal;
    })

    console.log(saveObject);

    this.customerSvc.updateProject('https://' + this.nodeUrl, this.originalProject.id, saveObject).subscribe(reso=>{
      console.log("saved: ", reso)
      this.dialogRef.close();
    }, e=>{
      console.log("Ererrp", e)
    })



  }

  cancel(){
    this.dialogRef.close();
  }

}
