import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';

import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = AuthService.getAuthorizationHeader();

    const excemptUrls = [
      'https://dev.rayvn.global/user-global-data/v1/public/users/login',
      'https://dev.rayvn.global/geo-position-log/v1/geo/context',
      'https://microservice.rayvn.global/geo-position-log/v1/geo/context'
    ]

    let authReq;
    if (!excemptUrls.find(url => url === req.url) && req.url.indexOf('public/agreements/licenses') === -1) {
      authReq = authHeader ? req.clone({headers: req.headers.set('Authorization', authHeader)}) : req;
    } else {
      authReq = req;
    }

    return next.handle(authReq);
  }
}
