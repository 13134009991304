import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EulasService {
  constructor(private http: HttpClient) { }
  getAll(nodeUrl): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/projects/licenses');
  }
}

