import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CustomersService} from '../../services/customers.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete} from '@angular/material';
import * as moment from 'moment';
import {DialogData} from '../save-customer-details-preview/save-customer-details-preview.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {LicenseTiersService} from '../../services/license-tiers.service';
import {LicenseLevelsService} from '../../services/license-levels.service';
import {ModuleLicenseService} from '../../services/module-license.service';
import {RegionsService} from '../../services/regions.service';
import {MatSnackBar} from '@angular/material/snack-bar';

import * as _ from 'lodash';
import {EulasService} from '../../services/eulas.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CustomerCreateComponent implements OnInit {
  project: any;
  customer: any;
  node: any;
  licenseExpiresAt: any;
  tierLicenseTypes: any;
  licenseLevels: any;
  moduleTypes: any;
  errorSaving: boolean;

  createNew = false;
  filteredRegions: Observable<string[]>;
  regionCtrl = new FormControl();
  allRegions: Array<any> = [];
  @ViewChild('regionInput') regionInput: ElementRef<HTMLInputElement>;
  @ViewChild('regionAuto') regionAutocomplete: MatAutocomplete;

  filteredSectors: Observable<string[]>;
  sectorCtrl = new FormControl();
  allSectors = [];
  @ViewChild('sectorInput') sectorInput: ElementRef<HTMLInputElement>;
  @ViewChild('sectorAuto') sectorAutocomplete: MatAutocomplete;

  filteredServices: Observable<string[]>;
  serviceCtrl = new FormControl();
  allServices = [];
  @ViewChild('serviceInput') serviceInput: ElementRef<HTMLInputElement>;
  @ViewChild('serviceAuto') serviceAutocomplete: MatAutocomplete;

  tiers = {
    LICENSE_TIER_BASE: 'Base',
    LICENSE_TIER_STANDARD: 'Standard',
    LICENSE_TIER_ADVANCED: 'Advanced',
    LICENSE_TIER_ENTERPRISE: 'Enterprise'
  };

  licenseLevelLabels = {
    LICENCE_LEVEL_TRIAL: 'Trial',
    LICENCE_LEVEL_EVENT: 'Event',
    LICENCE_LEVEL_RAYVN: 'Rayvn',
    LICENCE_LEVEL_TIERED: 'Tiered',
    LICENCE_LEVEL_DSB: 'DSB',
    LICENCE_LEVEL_SINGLE: 'Single User',
    LICENCE_LEVEL_LEGACY: 'Legacy'
  };

  licenseLevelKeys = [
    'LICENCE_LEVEL_TRIAL',
    'LICENCE_LEVEL_EVENT',
    'LICENCE_LEVEL_RAYVN',
    'LICENCE_LEVEL_TIERED',
    'LICENCE_LEVEL_DSB',
    'LICENCE_LEVEL_SINGLE',
    'LICENCE_LEVEL_LEGACY'
  ];

  saving = false;
  enabledStatusChanged = false;
  demoStatusChanged = false;
  mfaStatusChanged = false;
  disableEditEmail: boolean;
  availableEULAs: Array<any>;
  selectedEULAs: Array<any>;
  constructor(
    public dialogRef: MatDialogRef<CustomerCreateComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private customerSvc: CustomersService,
    private adapter: DateAdapter<any>,
    private licenseTiersSvc: LicenseTiersService,
    private licenseLevelsSvc: LicenseLevelsService,
    private eulasService: EulasService,
    private moduleLicenseSvc: ModuleLicenseService,
    private regionSvc: RegionsService,
    private _snackBar: MatSnackBar
  ) {
    this.customer = data.customer;
    this.node = data.node;



    this.filteredSectors = this.sectorCtrl.valueChanges.pipe(
      startWith(null),
      map((sector: string | null) => sector ? this._filterSectors(sector) : this.allSectors.slice()));

    this.filteredServices = this.serviceCtrl.valueChanges.pipe(
      startWith(null),
      map((service: string | null) => service ? this._filterServices(service) : this.allServices.slice()));

    this.filteredRegions = this.regionCtrl.valueChanges.pipe(
      startWith(null),
      map((region: string | null) => region ? this._filterRegions(region) : this.allRegions.slice()));
    }

  async ngOnInit() {
    this.availableEULAs = await this.eulasService.getAll(this.node.url)
      .map(eulas => eulas.map(eula => ({name: eula, selected: false})))
      .toPromise();

    if (this.customer.id) {
      this.customerSvc.getProject(this.node.url, this.customer.id).subscribe(project => {
        this.project = project;
        this.project.regions = this.project.regions.map(json => JSON.parse(json));
        if (!this.project._embedded.mainContactCustomer) {
          this.project._embedded.mainContactCustomer = {};
        }
        if (this.project.licenses) {
          this.selectedEULAs = this.project.licenses || [];
            _.forEach(this.selectedEULAs, (selected) => {
              const eula = this.availableEULAs.find(a => selected === a.name);
              if (eula) { eula.selected = true; }
            });
        }
        this.adapter.setLocale(this.project.standardProjectLocale);
        this.disableEditEmail = true;
      });
    } else {
      this.disableEditEmail = false;
      this.project = {
        standardProjectLocale: 'en',
        enabled: true,
        _embedded: {
          moduleLicenses: [],
        }
      };
    }

    this.moduleLicenseSvc.getAllTypes(this.node.url).subscribe(t => {
      this.moduleTypes = t;
    });
    this.licenseTiersSvc.getAll(this.node.url).subscribe(types => {
      this.tierLicenseTypes = types;
    });
    this.customerSvc.getAllServices(this.node.url).subscribe(services => {
      this.allServices = services.sort((a, b) => a.localeCompare(b));
    });
    this.customerSvc.getAllSectors(this.node.url).subscribe(sectors => {
      this.allSectors = sectors.sort((a, b) => a.localeCompare(b));
    });
    this.regionSvc.getAllRegions(this.node.url).subscribe(regions => {
      this.allRegions = regions.sort((a, b) => a.name.localeCompare(b.name));
    });
    this.licenseLevelsSvc.getAll(this.node.url).subscribe(levels => {
      this.licenseLevels = levels;
    });
  }

  enabledColor() {
    if (this.project.enabled) {
      return 'primary';
    } else {
      return 'warn';
    }
  }

  compareFn(module1, module2) {
    console.log('module1', module1);
    console.log('module2', module2);
    return module1 && module2 ? module1 === module2.moduleType : module1 === module2;
  }

  save() {
    this.saving = true;

    _.forEach(this.project._embedded.moduleLicenses, m => {
      m.expiresAt = moment(m.expiresAt).toDate().getTime();
      m.name = m.moduleType;
    });

    const createObject = {
      'locale': this.project.standardProjectLocale,
      'name': this.project.name,
      'customerLicense': {
        'startAt': new Date().getTime(),
        'expiresAt': moment(this.project.licenseExpiresAt).toDate().getTime(),
        'userLimit': this.project.userLimit,
        'contactLimit': this.project.contactLimit,
        'responderLimit': this.project.responderLimit,
        'level': this.project.licenseLevel,
        'tierLicenseType': this.project.tierLicenseType,
        'modules': this.project._embedded.moduleLicenses
      },
      'project': this.project
    };

    this.customerSvc.createProject(this.node.url, createObject).subscribe(result => {
      this.saving = false;
      if (this._snackBar) {
        this._snackBar.dismiss();
      }
      if (!this.createNew) {
        this.dialogRef.close(result);
      } else {
        this.project.name = '';
        this.project.crmId = '';
        this._snackBar.open('Project created', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          politeness: 'polite'
        });
      }

    }, error => {
      this.saving = false;
      this.errorSaving = true;
      this.openSnackBar(error);
    });

  }


  openSnackBar(error) {
    let errorText = '';
    if (error.error.error.errors.length === 1) {
      errorText = error.error.error.errors[0].message;
    } else if (error.error.error.errors.length > 1) {
      _.forEach(error.error.error.errors, e => {
        errorText += e.message + '\n';
      });
    }


    if (errorText.indexOf('content:') !== -1) {
      const textArray = errorText.split('content:');
      _.forEach(textArray, t => {
        if (t.substring(0, 5).indexOf('{') !== -1) {
          const newError = JSON.parse(t);
          try {
            errorText = JSON.parse(newError.error.errors[0].message).message;
          } catch (e) {
            errorText = newError.error.errors[0].message;
          }

        }
      });
    }
    this._snackBar.open('Error: ' + errorText , 'OK', {
      verticalPosition: 'top',
      politeness: 'assertive',
      panelClass: 'error-snackbar',
    });
  }

  addModule(moduleType) {
    this.project._embedded.moduleLicenses.push({
      expiresAt: moment().add(1, 'y'),
      moduleType: moduleType
    });
  }

  removeModule(module) {
    _.remove(this.project._embedded.moduleLicenses, m => m === module);
  }

  close() {
    this.dialogRef.close();
  }

  getMomentDate(timestamp) {
    const date =  new Date();
    date.setTime(timestamp);
    return date;
  }

  changeLicenseDate(event) {
    this.project.licenseExpiresAt = moment(event.value).toDate();
   }

  changeModuleLicenseDate(module, event) {
    module.expiresAt = moment(event.value).valueOf();
   }

  private _filterRegions(value: any): string[] {
    if (value instanceof Object) { return []; }
    const filterValue = value['id'];
    return this.allRegions.filter(region => region.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }

  private _filterSectors(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allSectors.filter(sector => sector.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterServices(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allServices.filter(service => service.toLowerCase().indexOf(filterValue) === 0);
  }
}
