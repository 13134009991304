import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import {AuthService} from '../services/auth.service';
import {Router, RouterModule, Routes} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import {
  MatButtonModule,
  MatToolbarModule,
  MatTabsModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatButtonToggleModule, MatDatepickerModule, DateAdapter, MatNativeDateModule, MatChipsModule, MatListModule,
  MatCardModule, MatProgressSpinnerModule, MatTableModule, MatPaginatorModule, MatGridListModule, MatSelectModule,
  MatSortModule, MatMenuModule, MatTooltipModule, MatSliderModule, MatCheckboxModule, MatBottomSheetModule, MatStepperModule
} from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import { NguiMapModule} from '@ngui/map';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {OrderByPipe} from '../pipes/orderBy.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterPipe} from '../pipes/filter.pipe';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { Location } from '@angular/common';
import { SaveCustomerDetailsPreviewComponent } from './save-customer-details-preview/save-customer-details-preview.component';
import {AppValuesService} from '../services/app-values.service';
import { CustomerTableComponent } from './customer-table/customer-table.component';
import { CallbackComponent } from './callback/callback.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ReportDownloadComponent } from './report-download/report-download.component';
import {CustomerCreateComponent} from './customer-create/customer-create.component';
import {AddMainContactComponent} from './add-main-contact/add-main-contact.component';
import {EditStakeholdersComponent} from './edit-stakeholders/edit-stakeholders.component';
import {CollaborationDialogComponent} from './collaboration-dialog/collaboration-dialog.component';


const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'login', component: LoginComponent },
  { path: 'customerinfo/:nodeUrl/:id', component: CustomerDetailsComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    OrderByPipe,
    FilterPipe,
    CustomerDetailsComponent,
    EditStakeholdersComponent,
    CustomerCreateComponent,
    CollaborationDialogComponent,
    AddMainContactComponent,
    SaveCustomerDetailsPreviewComponent,
    CustomerTableComponent,
    CallbackComponent,
    ReportDownloadComponent
  ],
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {enableTracing: false} // <-- debugging purposes only
        ),
      // tslint:disable-next-line:max-line-length
        NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyDdz861hd4Gdq6v_SUvXoh3qsRJvVpoqz0&libraries=visualization'}),
        FormsModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatToolbarModule,
        MatTabsModule,
        MatIconModule,
        MatExpansionModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatGridListModule,
        MatSelectModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatSortModule,
        MatMenuModule,
        MatTooltipModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatBottomSheetModule,
        MatStepperModule
    ],
  providers: [
    Location,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppValuesService
  ],
  entryComponents: [
    SaveCustomerDetailsPreviewComponent,
    ReportDownloadComponent,
    CustomerCreateComponent,
    CollaborationDialogComponent,
    AddMainContactComponent,
    EditStakeholdersComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
