import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {CustomersService} from '../../services/customers.service';
import {mapStyle} from './map-style';
import * as _ from 'lodash';
import * as moment from 'moment';
import {NodesService} from '../../services/nodes.service';
import {AppValuesService} from '../../services/app-values.service';
import {MatSort, MatTabGroup} from '@angular/material';
import {UserPositionsService} from '../../services/user-positions.service';
import {Observable, Subject} from 'rxjs/Rx';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Location} from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('tabsRef') tabsRef: MatTabGroup;
  pos: any;
  nodes: any;
  marker: any;
  step: number;
  mapOptions: any;
  selectedTabIndex = 0;
  positions: any;
  fromTime: any;
  toTime: any;
  toTimeAmount = 7;
  sliderValue = 7;
  sliderObserver = new Subject();
  slider$: Observable<any>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private route: ActivatedRoute, private customers: CustomersService, private nodeSvc: NodesService, private appValuesService: AppValuesService, private userPositions: UserPositionsService, private zone: NgZone) {
    this.marker = {
      display: true,
      applicationName: null,
      lat: null,
      lng: null,
    };

    this.mapOptions = {
      styles: mapStyle,
      center: { lat: 30, lng: 0 },
      zoom: 4
    };

    this.slider$ = this.sliderObserver.asObservable();
  }

  ngOnInit() {
    const nodes = this.nodeSvc.getAll().subscribe(n => {
      this.nodes = n;
      if (this.nodes.length) {
        _.forEach(this.nodes, n => {
          n.pos = {
            lat: +n.latitude,
            lng: +n.longitude
          };
        });
      }

      const queryTab = this.route.snapshot.queryParamMap.get('tab');
      const tab = queryTab ? +queryTab : 0;
      this.tabsRef.selectedIndex = tab;

    });

    this.step = 0;
  }

  onMapReady(map) {
    this.positions = new google.maps.MVCArray([]);
    const heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.positions
    });
    heatmap.setMap(map);
    heatmap.set('radius', 30);
    heatmap.set('opacity', 1);

    Observable.combineLatest(
      this.userPositions.poll(),
      this.slider$
    ).subscribe((results) => {
      this.zone.run(() => {
        const geoPositions = results[0];
        const sliderValue  = results[1];
        this.positions.clear();

        this.fromTime = moment().subtract(sliderValue, 'days');
        this.toTime = moment(this.fromTime).add(this.toTimeAmount, 'days');
        geoPositions
          .filter(position => moment(position.timestamp).isBetween(this.fromTime, this.toTime))
          .forEach(p => this.positions.push({rowKey: p.rowKey, location: new google.maps.LatLng(+p.latitude, +p.longitude)}));
      });
    });
    this.updateSliderValue(7);
  }

  clicked(index, node, {target: marker}) {
    this.marker.name = node.name;
    this.marker.selectedIndex = index + 1;
    this.marker.lat = marker.getPosition().lat();
    this.marker.lng = marker.getPosition().lng();
    marker.nguiMapComponent.openInfoWindow('iw', marker);
  }

  selectTab(tabsRef, marker) {
    tabsRef.selectedIndex = this.marker.selectedIndex;
    this.navigateToTab(this.marker.selectedIndex);
  }

  navigateToTab(tabIndex: number) {
    const queryParams: Params = { tab: tabIndex };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  tabClicked(event) {
    this.navigateToTab(event.index);
    this.nodes[event.index - 1].customers = [];
    this.nodes[event.index - 1]._loading = true;
    this.appValuesService.setSelectedMainTab(event.index);
    if (this.nodes[event.index - 1].url) {
      this.customers.getNodeCustomers(this.nodes[event.index - 1].url, true, true).subscribe(res => {
        this.nodes[event.index - 1].validCustomers = 0;
        this.nodes[event.index - 1].invalidCustomers = 0;
        this.nodes[event.index - 1].customers = res
        _.forEach(res, c => {
          c.validLisence = !this.isExpiredOrInvalid(c);
          if (c.validLisence) {
            this.nodes[event.index - 1].validCustomers++;
          } else {
            this.nodes[event.index - 1].invalidCustomers++;
          }
        });
        this.nodes[event.index - 1]._loading = false;
      });
    }
  }

  getStep() {
    return this.step;
  }


  setStep(index: number) {
    this.step = index;
  }



  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  sliderChange(event) {
    this.sliderValue = event.value;

    this.updateSliderValue(event.value);
  }

  updateSliderValue(value) {
    this.sliderObserver.next(value);
  }

  getSliderDate() {
    return moment().subtract(this.sliderValue, 'days').format('ll');
  }

  isExpiredOrInvalid(customer){
    let expiryDate = moment(customer.licenseExpiresAt);
    if(!expiryDate.isValid()){
      return true;
    }
    if(expiryDate.isValid() && expiryDate.isAfter(moment())){
      return false;
    }
    return true;
  }





}
