import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppValuesService {
  static _selectedMainTab: number = 0;
  constructor() { }


  public getSelectedMainTab(): number {
    return AppValuesService._selectedMainTab;
  }

  public setSelectedMainTab(number: number): void {
    AppValuesService._selectedMainTab = number;
  }

}
