import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NodesService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  getAll() {
    return this.http.get(environment.url.applications); // TODO Decide dev. or microservice. in build script
  }



}
