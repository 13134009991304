import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../environments/environment';
import * as auth0 from 'auth0-js';


(window as any).global = window;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  auth0 = new auth0.WebAuth(environment.auth0);

  constructor(public router: Router) { }

  public static getAuthorizationHeader() {
    const token = localStorage.getItem('id_token');
    return token ? 'Bearer ' + token : null;
  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    if (this.isAuthenticated()) {
      this.router.navigate(['/home']);
    } else {

      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.setSession(authResult);
          this.router.navigate(['/home']);
        } else if (err) {
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
  }
  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    // this.router.navigate(['/']); // Rather logout from auth0
    this.auth0.logout({
      returnTo: environment.auth0.redirectUri
    });
  }

  public getApiUrl() {
    return localStorage.getItem('apiUrl');
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

}
