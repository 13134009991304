import {Component, Inject, OnInit} from '@angular/core';
import {CustomersService} from '../../services/customers.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogData} from '../save-customer-details-preview/save-customer-details-preview.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import * as _ from 'lodash';

@Component({
  selector: 'app-edit-stakeholders',
  templateUrl: './edit-stakeholders.component.html',
  styleUrls: ['./edit-stakeholders.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  ],
})
export class EditStakeholdersComponent implements OnInit {
  project: any;
  customer: any;
  node: any;
  licenseExpiresAt: any;
  errorSaving: boolean;

  saving = false;
  private customers: any[];
  private stakeholderTeams: any[];
  private teams: any[];
  private selectedTeam: any;
  private selectedStakeholder: any;
  private selectedStakeholderTeam: any;
  private collaborationAgreements: any;
  private stakeholders: any[];
  private agreementExists: boolean;
  constructor(
    public dialogRef: MatDialogRef<EditStakeholdersComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private customerSvc: CustomersService,
    private _snackBar: MatSnackBar
  ) {
    this.customer = data.customer;
    this.node = data.node;
    this.customers = data.customers.filter(c => c.id !== this.customer.id); // remove current customer from list
  }

  async ngOnInit() {
    this.stakeholders = [...this.customers].sort((a, b) => {
      if (!a.name) { return -1; }
      if (!b.name) { return 1; }
      return a.name.localeCompare(b.name);
    });
    this.customerSvc.getTeamsForProject(this.node.url, this.customer.id).subscribe(teams => {
      console.log(teams);
      this.teams = teams;
      if (teams.length === 1) {
        this.selectedTeam = teams[0];
      }
    });

    this.getCollaborationAgreements();
  }

  getCollaborationAgreements() {
    this.customerSvc.getProjectStakeholders(this.node.url, this.customer.id).subscribe(stakeholders => {
      this.collaborationAgreements = stakeholders.sort((a, b) => a.externalProjectName.localeCompare(b.externalProjectName));
    });
  }

  add() {
    this.saving = true;
    this.customerSvc.establishCollaborationAgreement(this.node.url, this.selectedTeam, this.selectedStakeholderTeam).subscribe(result => {
      this.selectedStakeholder = null;
      this.selectedStakeholderTeam = null;
      this.getCollaborationAgreements();
      this.saving = false;
    });
  }

  selectStakeholder() {
    if (!this.selectedStakeholder) { return; }

    if (this.collaborationAgreements.find(ca => ca.externalProjectId === this.selectedStakeholder.id)) {
      this.agreementExists = true;
    }
    else {
      this.agreementExists = false;
    }

    this.customerSvc.getTeamsForProject(this.node.url, this.selectedStakeholder.id).subscribe(teams => {
      this.stakeholderTeams = teams;
      if (teams.length === 1) {
        this.selectedStakeholderTeam = teams[0];
      }
    });
  }


  openSnackBar(error) {
    let errorText = '';
    if (error.error.error.errors.length === 1) {
      errorText = error.error.error.errors[0].message;
    } else if (error.error.error.errors.length > 1) {
      _.forEach(error.error.error.errors, e => {
        errorText += e.message + '\n';
      });
    }


    if (errorText.indexOf('content:') !== -1) {
      const textArray = errorText.split('content:');
      _.forEach(textArray, t => {
        if (t.substring(0, 5).indexOf('{') !== -1) {
          const newError = JSON.parse(t);
          try {
            errorText = JSON.parse(newError.error.errors[0].message).message;
          } catch (e) {
            errorText = newError.error.errors[0].message;
          }

        }
      });
    }
    this._snackBar.open('Error: ' + errorText , 'OK', {
      verticalPosition: 'top',
      politeness: 'assertive',
      panelClass: 'error-snackbar'
    });
  }

  close() {
    this.dialogRef.close();
  }
}
