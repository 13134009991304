import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CustomersService} from '../../services/customers.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource} from '@angular/material';
import {DialogData} from '../save-customer-details-preview/save-customer-details-preview.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {of} from 'rxjs/observable/of';
import {concatMap, map, mergeMap, tap, toArray} from 'rxjs/operators';
import * as XLSX from 'xlsx';

declare var Highcharts: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-collaboration-dialog',
  templateUrl: './collaboration-dialog.component.html',
  styleUrls: ['./collaboration-dialog.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CollaborationDialogComponent implements OnInit {

  node: any;
  private customers: any[];
  private dataSource: MatTableDataSource<any>;
  columnsToDisplay = ['name'];
  private agreementData = [];

  public chartOptions = null;
  private loading: boolean;
  private loaded: number;
  private masterCustomerList: any[];
  private agreementsCompiled: boolean;

  constructor(
    public dialogRef: MatDialogRef<CollaborationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private customerSvc: CustomersService,
    private _snackBar: MatSnackBar,
    private elRef: ElementRef
  ) {
    this.node = data.node;
    this.masterCustomerList = data.customers;
    console.log(this.masterCustomerList);
  }
  async ngOnInit() {
    const serverColorMapping = {
      'elu-dev': '#bb3838',
      'elu-unstable': '#00c4ff',
      'elu-eu': '#1a6c1a',
      'elu-no': '#252575',
      'elu-us': '#b07737',
      'elu-useast': '#9ab037',
      'elu-sa': '#b037b0',
    };

    const statusColorMapping = {
      'ACCEPTED': '#1a6c1a',
      'REJECTED': '#bb3838',
      'PENDING': '#b07737',
    };
    this.loading = true;
    this.customers = this.masterCustomerList.map(c => ({id: c.id, name: c.name, timeCreated: c.timeCreated}));
    this.dataSource = new MatTableDataSource(this.customers);
    this.loaded = 0;
    this.agreementData = await of(...this.customers).pipe(
      // Use concatMap to process each customer sequentially
      mergeMap(c =>
        this.customerSvc.getProjectStakeholders(this.node.url, c.id).pipe(
          tap(() => this.loaded++),
          // Transform the stakeholders into the agreements format
          map(stakeholders => stakeholders.map(s => ({
            from: {
              id: c.id,
              name: c.name,
              status: s.state,
              backend: this.node.name,
            },
            to: {
              id: s.externalProjectId,
              name: s.externalProjectName,
              status: s.externalState,
              backend: s.externalBackend,
            },
            metadata: {
              timeCreated: s.timeCreated,
              deleted: s.hasBeenSafelyDeleted,
            }
          })))
        ), 4
      ),
      // Collect all emitted arrays into a single array
      toArray(),
      // Flatten the array of arrays into a single array
      map(agreementsArrays => _.flatten(agreementsArrays))
    ).toPromise();
    this.loading = false;

    const container = this.elRef.nativeElement.querySelector('#networkGraphContainer');

    function calculateRadius(connections) {
      // This is a simple linear scaling. You might want to use a logarithmic scale or
      // any other scaling that suits your data and visualization needs.
      const baseSize = 5; // Minimum size of the node
      return Math.min(baseSize + connections, 20);
    }

    const nodesMap = {};
    const links = [];

    const connectionsCount = {};

    this.agreementData.forEach(connection => {
      if (!connection.from.id || !connection.to.id) { console.log('null'); return; }
      if (connection.from.id === connection.to.id) { console.log('same'); return; }
      nodesMap[connection.from.id] = {
        id: connection.from.id,
        name: connection.from.name,
        color: serverColorMapping[connection.from.backend] || '#c03ce5',
        marker: {
          // radius: calculateRadius(connectionsCount[connection.from.id])
        }
      };
      nodesMap[connection.to.id] = {
        id: connection.to.id,
        name: connection.to.name,
        color: serverColorMapping[connection.to.backend] || '#c03ce5',
        marker: {
          // radius: calculateRadius(connectionsCount[connection.to.id])
        }
      };

      links.push({
        from: connection.from.id,
        to: connection.to.id,
        color: statusColorMapping[connection.to.status] || '#7e7e7e'
      });

      if (connectionsCount[connection.from.id]) {
        connectionsCount[connection.from.id]++;
      } else {
        connectionsCount[connection.from.id] = 1;
      }

      // Increment the count for the 'to' node
      if (connectionsCount[connection.to.id]) {
        connectionsCount[connection.to.id]++;
      } else {
        connectionsCount[connection.to.id] = 1;
      }
    });

    this.agreementsCompiled = true;

    const nodes = Object.values(nodesMap);
    this.chartOptions = {
      chart: {
        type: 'networkgraph'
      },
      title: {
        text: 'Customer Connections'
      },
      tooltip: {
        enabled: false // Disables tooltips
      },
      series: [{
        type: 'networkgraph',
        data: links,
        nodes: nodes,
        dataLabels: {
          enabled: true,
          linkFormat: ''
        },
        marker: {
          radius: 10
        },
        linkWidth: 5
      }],
      plotOptions: {
        series: {
          animation: false, // Disables animation for all series
        },
        networkgraph: {
          layoutAlgorithm: {
            enableSimulation: true,
            gravitationalConstant: 0.3, // Increase the gravity
            linkLength: 10, // Adjust link length
            // Other parameters as needed
          }
        }
      },
    };

    Highcharts.chart(container, this.chartOptions);
  }

  async writeExcelFile() {
    const headers = [
      'Inviter ID',
      'Inviter Name',
      'Inviter Status',
      'Inviter Backend',
      'Invitee ID',
      'Invitee Name',
      'Invitee Status',
      'Invitee Backend',
      'Time Created',
      'Deleted',
    ]
    const rows = this.agreementData.map(agreement => [
      agreement.from.id,
      agreement.from.name,
      agreement.from.status,
      agreement.from.backend,
      agreement.to.id,
      agreement.to.name,
      agreement.to.status,
      agreement.to.backend,
      new Date(agreement.metadata.timeCreated).toLocaleString('no-NB'),
      agreement.metadata.deleted,
    ]);
    const data = [headers, ...rows];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Collaborationagreements');

    // Write the workbook to a file
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Function to convert the workbook to a binary string
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    }

    // Create a Blob and trigger a download
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.node.name + '.xlsx';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  openSnackBar(error) {
    let errorText = '';
    if (error.error.error.errors.length === 1) {
      errorText = error.error.error.errors[0].message;
    } else if (error.error.error.errors.length > 1) {
      _.forEach(error.error.error.errors, e => {
        errorText += e.message + '\n';
      });
    }


    if (errorText.indexOf('content:') !== -1) {
      const textArray = errorText.split('content:');
      _.forEach(textArray, t => {
        if (t.substring(0, 5).indexOf('{') !== -1) {
          const newError = JSON.parse(t);
          try {
            errorText = JSON.parse(newError.error.errors[0].message).message;
          } catch (e) {
            errorText = newError.error.errors[0].message;
          }

        }
      });
    }
    this._snackBar.open('Error: ' + errorText , 'OK', {
      verticalPosition: 'top',
      politeness: 'assertive',
      panelClass: 'error-snackbar',
    });
  }

  close() {
    this.dialogRef.close();
  }

  getMomentDate(timestamp) {
    const date =  new Date();
    date.setTime(timestamp);
    return date;
  }
}
