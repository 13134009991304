import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(private http: HttpClient, private auth: AuthService) {

  }

  createProject(nodeUrl, project) {
    return this.http.post(nodeUrl + 'admin/v1/projects/customer', project);
  }

  getNodeCustomers(nodeUrl, enabled?, demo?) {
    const params = [];
    // params.push('limit=20');
    if (enabled) { params.push('enabled=true'); }
    if (demo) { params.push('demo=false'); }
    const parameterString = params.length > 0 ? '?' + params.join('&') : '';

    return this.http.get(nodeUrl + 'admin/v1/projects' + parameterString).map(result => result['content']);
  }

  generateReport(nodeUrl) {
    return this.http.post(nodeUrl + 'admin/v1/projects/licenseExceededReport', {});
  }

  getProject(nodeUrl, projectId) {
    return this.http.get(nodeUrl + 'admin/v1/projects/' + projectId + '?embedded=moduleLicenses,mainContactCustomer');
  }

  saveProject(nodeUrl, project, incluideMainContactCustomer = true) {
    const queryString = incluideMainContactCustomer ? '?embedded=moduleLicenses,mainContactCustomer' : '?embedded=moduleLicenses';
    return this.http.put(nodeUrl + 'admin/v1/projects/' + project.id + queryString, project);
  }

  updateProject(nodeUrl, projectId, object) {
    return this.http.put(nodeUrl + '/admin/v1/projects/' + projectId, object);
  }

  createProjectContact(nodeUrl, projectId, mainContactCustomer) {
    return this.http.post(nodeUrl + 'admin/v1/projects/' + projectId + '/adminUser', mainContactCustomer);
  }

  updateProjectContact(nodeUrl, userId, object) {
    return this.http.put(nodeUrl + 'admin/v1/users/' + userId, object);
  }

  impersonateProjectContact(nodeUrl, projectId) {
    return this.http.post(nodeUrl + 'admin/v1/projects/' + projectId + '/adminUser/impersonate', {});
  }

  getAllServices(nodeUrl): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/projects/services');
  }

  getAllSectors(nodeUrl): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/projects/sectors');
  }

  getTeamsForProject(nodeUrl, projectId): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/teams/?project=' + projectId + '&embedded=group.project').map(result => result['content']);
  }
  getProjectStakeholders(nodeUrl, projectId): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/collaborationAgreements/?project=' + projectId + '&embedded=stakeholder').map(result => result['content']);
  }

  establishCollaborationAgreement(nodeUrl, inviterTeam, inviteeTeam) {
    return this.http.post(nodeUrl + 'admin/v1/collaborationAgreements/establish', {inviterTeam, inviteeTeam});
  }

}
