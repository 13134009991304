import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  constructor(private http: HttpClient) {

  }

  createUpdateRegion(nodeUrl, region) {
    return this.http.post(nodeUrl + 'admin/v1/regions', region);
  }

  getAllRegions(nodeUrl): Observable<any> {
    return this.http.get(nodeUrl + 'admin/v1/regions');
  }
}
