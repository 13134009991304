import {Pipe} from '@angular/core';
import * as _ from 'lodash';


@Pipe({
  name: 'orderby',
  pure: false
})
export class OrderByPipe{
  transform(array, args) {
    let orderDirection = 'asc';
    if(args.substring(0, 1) == '-'){
      orderDirection = 'desc';
    }
    return _.orderBy(array, args.substring(1), orderDirection);
  }
}
