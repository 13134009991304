import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LicenseLevelsService {

  constructor(private http: HttpClient) { }
  getAll(nodeUrl) {
    return this.http.get(nodeUrl + 'admin/v1/projects/licenseLevels');
  }
}
