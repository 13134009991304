import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/Rx';
import {Observable} from 'rxjs/Rx';
import {environment} from '../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserPositionsService {
  pollingData: any;

  constructor(private http: HttpClient) {
    this.pollingData = Observable
      .timer(0, 60000)
      .switchMap(() => http.get(environment.url.geo))
      .map(data => data);
  }

  poll(): Observable<any> {
    return this.pollingData;
  }
}
