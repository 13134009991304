import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CustomersService} from '../../services/customers.service';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.css']
})
export class ReportDownloadComponent implements AfterViewInit {

  link: string = null;
  reportGenerated = false;
  node: Object;

  constructor(private customers: CustomersService, private cd: ChangeDetectorRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.customers.generateReport(this.node['url']).subscribe(result => {
        this.reportGenerated = true;
        this.link = result['links'][0];
        console.log(this.link);
        this.cd.detectChanges();
      });
    }, 100);
  }

}
